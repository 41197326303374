import { Link } from "gatsby"
import React, { useRef } from "react"
import { css, cx } from "emotion"
import Img, { FluidObject } from "gatsby-image"
import { colors, spacing, typography, Breakpoints } from "@avonova-noa/common"
import { TypeLabel, typeVariations } from "./typeLabel";

const horizontalLayout = css`
  display: grid;
  grid-template-columns: 50% 1fr; /* It looks like 50% takes always 50%  */
  position: relative;
  grid-column-gap: ${spacing.s};
  grid-row-gap: ${spacing.ml};
 
  h2 {
    ${typography.h7}
  }

  .innerWrapper {
    text-align: left;
    position: static;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  p {
    display: none;
    color: ${colors.green.green11};
  }
`
const articlePreview = css`
  display: block;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;

  .gatsby-image-wrapper>div {
    padding-bottom: 56.25% !important; // 16:9 ratio
  }

  h2 {
    color: ${colors.green.dark2};
    font-family: ${typography.serif};
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    margin: 0 auto ${spacing.ms};
  }

  p {
    ${typography.bodySerif}
    color: ${colors.green.green11};
  }

  .description {
    margin-bottom: ${spacing.s};
  }

  .innerWrapper {
    margin: 0 auto;
    border-radius: 7px;
    position: relative;
    text-align: center;
    
    @media screen and (${Breakpoints.mobile}) {
      padding-top: ${spacing.ml};
    }
  }

  img {
      transition: all 0.4s !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
  }

  &.hover {
    h2,p {opacity: 0.8;}
 
    img {
      transform: translate(-50%, -50%) scale(1.05) !important;
    }
  }
`
const link = css`
  color: ${colors.green.green11};
  font-family: ${typography.sansSerif};
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  border-bottom: 1px solid ${colors.green.medium3};
  text-transform: uppercase;
  display: inline-block;
  pointer-events: auto !important;
  margin-bottom: 1px !important;

  &:hover {
    border-bottom: 2px solid ${colors.green.medium3};
    margin-bottom: 0px !important;
  }
`

const featured = css`
  text-align: center;
    .innerWrapper h2 {
      ${typography.h1}
      margin: 0 auto ${spacing.ms};

      @media screen and (${Breakpoints.mobile}) { 
        ${typography.h2}
      }
    }
   
  @media screen and (${Breakpoints.mobile}) { 
      margin: 0 -16px;
  }
  
  .innerWrapper {
    padding: ${spacing.xl} ${spacing.huge} 0 ;

    @media screen and (${Breakpoints.mobile}) { 
      padding: ${spacing.ml} 0 0; 
    }
  }

  .gatsby-image-wrapper {
    @media screen and (${Breakpoints.mobile}) { 
      margin: 0%;
    }
  }

`

const threeFourths = css`
  h2 {
    ${typography.h2}

    @media screen and (${Breakpoints.mobile}) {
      ${typography.h2}
      margin: 0 auto ${spacing.s};
    
    }
  }

  .innerWrapper { 
    padding: ${spacing.l} ${spacing.l} 0;

    @media screen and (${Breakpoints.mobile}) {
        padding-left:0;
        padding-right:0;
    }
  }

  .gatsby-image-wrapper {
    @media screen and (${Breakpoints.mobile}) { 
      margin: 0%;
      
    }
  }
`

const half = css`
   .innerWrapper { 
    padding: ${spacing.ml} ${spacing.ml} 0;

    @media screen and (${Breakpoints.mobile}) {
        padding-left:0;
        padding-right:0;
      }
  }
 
 h2 {
    ${typography.h3}

    @media screen and (${Breakpoints.mobile}) {
      ${typography.h4}
      margin: 0 auto ${spacing.s};
    
    }
  }

  @media screen and (${Breakpoints.mobile}) {
    .gatsby-image-wrapper {
      margin: 0;
    }
  }

`

const oneThird = css`
  h2 {
    ${typography.h4}
    margin: 0 auto ${spacing.s};

    @media screen and (${Breakpoints.mobile}) {
      margin: 0 auto ${spacing.s};
      ${typography.h6}
    }
  }

  .gatsby-image-wrapper>div {
    padding-bottom: 66.7188% !important; // 3:2 ratio
  }

  .innerWrapper { 
    padding: ${spacing.m} ${spacing.m} 0
  }
  @media screen and (${Breakpoints.mobile}) {
      ${horizontalLayout}
  }
`

const oneFourth = css`
  h2 {
    ${typography.h6}
    margin: 0 auto ${spacing.s};
  }

  .gatsby-image-wrapper>div {
    padding-bottom: 66.7188% !important; // 3:2 ratio
  }

  .innerWrapper { 
    padding: ${spacing.m} 0 0;
  }

  @media screen and (${Breakpoints.mobile}) {
      ${horizontalLayout}
  }
`
const withLabel = css`
      h2{  
        ${typography.h3}
      }

 
        @media (${Breakpoints.mobile}){
    
          h2{  
            ${typography.h4} 
            margin: 0 auto ${spacing.s};
          }
     
          .gatsby-image-wrapper {
            margin-bottom: 31px;
          }
        }
`

export const sizeVariations = {
  featured,
  "3/4": threeFourths,
  half,
  "1/3": oneThird,
  "1/4": oneFourth,
  "default": "default"
} //TODO: maybe better naming than big and small

type KeysOf<T extends Record<string, any>> = T extends Record<infer K, any> ? K : never;

export type SizeVariations = KeysOf<typeof sizeVariations>
const availableVariations = Object.keys(sizeVariations);


export interface Post {
  id: string
  slug: string
  title: string
  tags: string[]
  previewSize: keyof typeof sizeVariations
  description: {
    description: string
  }
  publishDate: string
  heroImage: {
    file: {
      url: string
    }
    description?: string
    fluid: FluidObject
  }
  type: keyof typeof typeVariations
  category: {
    name: string
  }
}

export type ArticlePreviewProps = {
  post: Post
  size: keyof typeof sizeVariations
}

export const ArticlePreview: React.FC<ArticlePreviewProps> = ({
  size,
  post: { slug, title, heroImage, description, category, type },
}) => {

  const parent = useRef(null);
  return (
    <Link ref={parent}
      className={cx(articlePreview, { [withLabel]: type === ("Dypdykket" || "Pablo svarer") }, sizeVariations[size])}
      to={`/${slug}`}
      onMouseEnter={() => { parent && parent.current.classList.add("hover") }}
      onMouseLeave={() => { parent && parent.current.classList.remove("hover") }}
    >
      {!!heroImage && (
        <Img
          alt={heroImage.description}
          fluid={heroImage.fluid}
        />
      )}
      <article className="innerWrapper">
        <TypeLabel parent={parent} type={type} horizontal={size === "1/3" ||
          size === "1/4"} customCss={size === "1/4" ? css`padding: 2px 18px;` : ""}></TypeLabel>
        <h2>{title}</h2>
        <p className="description">{!!description && description.description}</p>
        {category && <span className={link}
          onMouseEnter={() => { parent && parent.current.classList.remove("hover") }}
          onMouseLeave={() => { parent && parent.current.classList.add("hover") }}
        >{category.name}</span>}
      </article>
    </Link >
  )
}
