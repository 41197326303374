import { Breakpoints, colors, typography } from "@avonova-noa/common";
import { css, cx } from "emotion";
import React from "react";

const typeLabelCSS = css`
    font-family: ${typography.sansSerif};
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 18px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);

    @media (${Breakpoints.mobile}){
      padding: 4px 16px;
    }
  `

const horizontalLabel = css`
@media (${Breakpoints.mobile}){
    left:0;
    width: 50%;
    bottom: 0;
    top: auto;
    transform: none;
    border-radius: 0;
    box-sizing: border-box;
}
`

const DypdykketCSS = css`
    background: ${colors.green.dark2};
    color: ${colors.green.green5};
    white-space: nowrap;
  
    &:hover  {
      background: ${colors.green.green5};
      color: ${colors.green.dark2};
    }
  `

const PabloSvarerCSS = css`
    background: ${colors.neutral.dark1};
    color: ${colors.yellow.medium1};

    &:hover {
      color: ${colors.neutral.dark1};
      background: ${colors.yellow.medium1};
    }
  `

export const typeVariations = {
  "Dypdykket": DypdykketCSS,
  "Pablo svarer": PabloSvarerCSS
}

export const TypeLabel = ({ type, parent, horizontal, customCss }: { type: keyof typeof typeVariations, parent?: any, horizontal?: boolean, customCss?: string }) => {
  return (
    <span className={cx(typeLabelCSS, typeVariations[type], customCss, { [horizontalLabel]: horizontal })}
      onMouseEnter={() => { parent && parent.current.classList.remove("hover") }}
      onMouseLeave={() => { parent && parent.current.classList.add("hover") }}
    >{type}</span>
  )
} 